//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Media',
    props: [
        'media',
        'user_id',
        'entity_id'
    ],
    data() {
        return {
            isFullScreen: false,
            isDeleted: false,
            videoTypes: [
                'mp4', 
                'avi',
                'mov'
            ],
            imageTypes: [
                'jpg',
                'jpeg',
                'gif',
                'png'
            ]
        }
    },
    methods: {
        mediaAction(media)
        {
            this.$store.state.profile.isProfileUpdate == false ? this.isFullScreen = true : this.isFullScreen = false
            if (this.$store.state.profile.isProfileUpdate && !this.isVideo(media)) {
                this.$q.dialog({
                    title: 'Update Profile Photo',
                    message: 'Are you sure you want to use this photo as your profile picture?',
                    cancel: true,
                })
                .onOk(() => {
                    this.$emit('selected_media_id',media.id)
                    this.$store.dispatch('profile/updateProfilePhotoById',media.id)
                    .then((r) => {
                        if(r.type == 'success'){
                            this.$q.notify({
                                message:'Successfully updated your profile photo',
                                color:'positive',
                                icon: 'check_circle'
                            })
                            location.reload()
                        }
                    })
                    this.$store.commit('profile/isUpdateProfileState',false)
                })
                .onDismiss(() => {})
                .onCancel(() => {})
            }
        },
        deleteMedia() {
            this.$q.dialog({
                title: 'Delete Media?',
                message: 'Are you sure that you want to delete this media?',
                cancel: 'No',
                color: 'negative',
                persistent: true,
                ok: 'Yes'
            }).onOk(() => {
                this.$http({
                    url: 'medias/' + this.media.id,
                    method: "DELETE",
                }).then((response) => {
                    if (response.data['status'] == 'media_deleted') {
                        this.isDeleted = true;
                        this.isFullScreen = false;
                        this.$q.notify({
                            color: 'positive',
                            message: "The media was deleted successfully.",
                            icon: 'check_circle'
                        });
                        this.$emit('deleted', this.media.id);
                    } else {
                        this.$q.notify({
                            color: 'negative',
                            message: "There was a problem deleting the media. Please try again.",
                            icon: 'error'
                        });
                    }
                });
            }).onOk(() => {
            }).onCancel(() => {
            }).onDismiss(() => {
            })   
        },
        isVideo(media) {
            if (this.videoTypes.includes(media.type)) {
                return true;
            }

            return false;
        },
        convertMediaToUrl(media, isThumbnail = false) {
            if (isThumbnail) {
                if (!this.isVideo(media)) {
                    return process.env.VUE_APP_APISERVER + 'gb_medias/'
                    + media.user_id + '/thumb_' + media.file_name + '.' + media.type;
                }
            }
                
            return process.env.VUE_APP_APISERVER + 'gb_medias/'
            + media.user_id + '/' + media.file_name + '.' + media.type;
        }
    }

}
